const hasToken = () => {
    return !!localStorage.getItem("token")
}
const getToken = () => {
    return `Bearer ${localStorage.getItem("token")}`;
}

const getHeaders = () => {
    let headers = {
        timeStamp: "timestamp",
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    }
    if (hasToken()) {
        headers['Authorization'] = getToken();
    }

    return headers;
}


export default {
    getHeaders
}