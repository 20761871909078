import axios from "axios";
import {
  urlPrefix,
  getLeaderBoardByDate,
  getLeaderBoard,
  getEventGallery,
  markAsAbusive,
  pinUsers,
  getAllEvents,
  secretToken,
  getUserDataByEventId,
  updateDataSource,
  registerInEvent,
  sendSmsApi,
  getCurrentSource,
  updateDataPerformance,
  unSubscribe,
  syncDataForGFitAndStrava,
  getPersonalTarget,
  getEventTarget,
  setPersonalTarget,
  postChallenge,
  postChallengeImages,
  getSubEvent,
  subscribeSubEvent,
  unSubscribeSubEvent,
  subEventUsersList,
  postSubEvent,
  postSubEventImages,
  getAllActivities,
  getAllMobile,
  sendChallengeInvite,
  viewChallenges,
  challengeAction,
  viewChallengeScorecard,
  rejoinInEvent,
  insertOrEditPaymentOfUser,
  getCountryList,
  getProgramWiseActivity,
  addProgramActivity,
  viewOldRecording,
  unRegisterUserFromEvent,
  getDashboardInfo,
  getPLotData,
  getTransactionReport,
  getAvailableCoach
} from "./apicollection";
import headers from './headers';

export const getChallengesByDate = (challengeIds) => {
  let challengesCombinedString = Array.isArray(challengeIds) ?
    challengeIds.join(",") :
    challengeIds;
  const URL = `${urlPrefix}${getLeaderBoardByDate}?challengerZoneIds=${challengesCombinedString}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getLeaderBoardData = (challengeId) => {
  const URL = `${urlPrefix}${getLeaderBoard}?challengerZoneId=${challengeId}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getEventGalleryData = (challengeId , pageNo) => {
  const URL = `${urlPrefix}${getEventGallery}?eventId=${challengeId}&page=${pageNo}&size=20`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const reportImage = (payload) => {
  const URL = `${urlPrefix}${markAsAbusive}`;
  return axios.put(URL, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const pinUsersAction = (payload) => {
  const URL = `${urlPrefix}${pinUsers}`;
  return axios.put(URL, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

function isLoggedIn() {
  if (localStorage.getItem("token")) {
    return true;
  }
  return false;
}

export const getAchievements = () => {
  const URL = `${urlPrefix}clients/getAchievementIcons`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${secretToken}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getOldEvents = () => {
  let str = window.location.href;
  let link = str.length;
  let final_link = "";

  {
    str.substr(str.length - 9) == "dashboard" ?
      (final_link = str.substr(8, link - 31)) :
      str.substr(str.length - 15) == "eventmanagement" ?
      (final_link = str.substr(8, link - 37)) :
      (final_link = str.substr(8, link - 22));
  }
  const url = window.location.href;
  const word = url.indexOf("://");
  const lastword = url.indexOf(".mhealth");
  let b = lastword;
  let a = word + 3;
  window.key = url.substr(a, b - 8);

  let bannerLink = window.location.href;
  let a_link = bannerLink.length;
  let banner = bannerLink.substr(8, a_link - 22);

  const URL = isLoggedIn() ?
    `${urlPrefix}${getAllEvents}?others=${
        window.key
      }&userId=${localStorage.getItem("userId")}` :
    `${urlPrefix}${getAllEvents}?others=${banner}&userId=559`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${secretToken}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getUserDetailsByEventID = (selectedEvent, selectedDataSource) => {
  const URL = `${urlPrefix}${getUserDataByEventId}?event=${selectedEvent.id}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const registerEvent = (payload) => {
  const URL = `${urlPrefix}${registerInEvent}`;
  return axios.post(URL, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const rejoinEvent = (eventId) => {
  const URL = `${urlPrefix}${rejoinInEvent}?eventId=${eventId}`;
  return axios.put(
    URL, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        timeStamp: "timestamp",
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
      },
    }
  );
};

export const updateSource = (payload) => {
  const URL = `${urlPrefix}${updateDataSource}?eventId=${payload.eventId}&datasource=${payload.datasource}`;
  return axios.put(
    URL, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        timeStamp: "timestamp",
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
      },
    }
  );
};

export const getAuthLink = (datasource) => {
  const URL = `${urlPrefix}v1.0/getAuthLinkByDatasource?datasource=${datasource}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const sendSms = (smsObj) => {
  const URL = `${urlPrefix}${sendSmsApi}`;
  let message = `Congratulations! You have successfully registered in ${smsObj.eventName}. You may subscribe to any of ongoing programs. Team mHealth`;

  let payload = {
    message: message,
    mobileNumber: smsObj.mobileNumber,
  };
  return axios.post(URL, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getDataCurrentSource = (eventId) => {
  const URL = `${urlPrefix}${getCurrentSource}?eventId=${eventId}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const addSupportingDoc = (payload) => {
  const URL = `${urlPrefix}${updateDataPerformance}`;
  return axios.post(URL, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Content-type": "multipart/form-data; boundary=???",
    },
  });
};

export const unsubscribeEvent = (eventID) => {
  const URL = `${urlPrefix}${unSubscribe}?eventId=${eventID}`;
  return axios.post(
    URL, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        timeStamp: "timestamp",
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
      },
    }
  );
};

export const syncGFitAndStrava = (action, eventId) => {
  const URL = `${urlPrefix}${syncDataForGFitAndStrava}?action=${action}&eventId=${eventId}`;
  return axios.put(
    URL, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        timeStamp: "timestamp",
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
      },
    }
  );
};

export const getPersonalTargetData = (id) => {
  const URL = `${urlPrefix}${getPersonalTarget}?event=${id}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getEventTargetData = (id) => {
  const URL = `${urlPrefix}${getEventTarget}?event=${id}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const setPersonalTargetData = (payload) => {
  const URL = `${urlPrefix}${setPersonalTarget}?eventId=${payload.eventId}&date=${payload.date}&distance=${payload.distance}&healthGoal=${payload.healthGoal}`;
  return axios.post(
    URL, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        timeStamp: "timestamp",
        accept: "*/*",
        "Content-type": "multipart/form-data; boundary=???",
      },
    }
  );
};

export const createOrUpdateEvent = (payload) => {
  const URL = `${urlPrefix}${postChallenge}`;
  return axios.post(URL, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const postEventImages = (payload, id) => {
  const URL = `${urlPrefix}${postChallengeImages}?eventId=${id}`;
  return axios.post(URL, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getActivitySubEvent = (id, payload) => {
  let URL = `${urlPrefix}${getSubEvent}?eventId=${id}`;
  if (payload.length > 0) {
    URL = `${URL}&startDate=${payload[0]}&endDate=${payload[1]}`;
  }

  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};
export const getDailyTips = () => {
  const URL = `${urlPrefix}v1.0/viewDailyTips`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};
export const saveViewTips = (id) => {
  const URL = `${urlPrefix}v1.0/saveUserViewTips?dailyTipId=${id}`;
  return axios.post(
    URL, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        timeStamp: "timestamp",
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
      },
    }
  );
};
export const tipsReactionRemark = (payload) => {
  const URL = `${urlPrefix}v1.0/reactionOrRemark`;
  return axios.post(URL, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};
export const getAllSubActivities = () => {
  // const URL =
  //     localStorage.getItem("event") != null
  //       ? `${urlPrefix}v1.0/getAllSubActivities?challengerzoneId=${localStorage.getItem(
  //           "event"
  //         )}`
  //       : `${urlPrefix}v1.0/getAllSubActivities`;
  const URL = `${urlPrefix}v1.0/getAllSubActivities`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getEventRoleWiseList = () => {
  const URL = `${urlPrefix}v1.0/getUserRoleWiseEvent`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const subscribeSubEventCall = (payload) => {
  let URL = `${urlPrefix}${subscribeSubEvent}?eventId=${payload.eventId}&subEventId=${payload.subEventId}&dataSource=WEB`;
  if (payload.rejoin) {
    URL = URL + "&rejoin";
  }
  return axios.post(
    URL, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        timeStamp: "timestamp",
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
      },
    }
  );
};

export const unSubcribeSubEventCall = (payload) => {
  const URL = `${urlPrefix}${unSubscribeSubEvent}?eventId=${payload.eventId}&subEventId=${payload.subEventId}`;
  return axios.put(
    URL, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        timeStamp: "timestamp",
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
      },
    }
  );
};

export const getSubEventUsersList = (id, source) => {
  const URL = `${urlPrefix}${subEventUsersList}?subEvent=${id}&dataSource=${source}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const createOrUpdateEventSubEvent = (payload, type, subEventId) => {
  const URL = `${urlPrefix}${
    type == "image"
      ? postSubEventImages + `?subEventId=${subEventId}`
      : postSubEvent
  }`;
  return type == "image" ?
    axios.put(URL, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        timeStamp: "timestamp",
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
      },
    }) :
    axios.post(URL, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        timeStamp: "timestamp",
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
      },
    });
};

export const getAllUserByMobile = (number) => {
  const URL = `${urlPrefix}${getAllMobile}?phoneNumber=${number}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const sendInviteForChallenge = (payload) => {
  let URL = `${urlPrefix}${sendChallengeInvite}?eventId=${payload.eventId}&mobileNumber=${payload.mobileNumber}&countryCode=${payload.countryCode}`; /*&inviteeId=${payload.inviteeId}`*/
  if (payload.inviteeName) {
    URL += `&inviteeName=${decodeURIComponent(payload.inviteeName)}`;
  }

  return axios.post(
    URL, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        timeStamp: "timestamp",
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
      },
    }
  );
};

export const getChallenges = (action, eventId) => {
  /**
   * action : Sender/Receiver
   * eventId: 1
   */
  let URL = `${urlPrefix}${viewChallenges}?eventId=${eventId}&action=${action}`;

  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const challengeActionCall = (action, challengeId) => {
  /**
   * action : accept/reject
   * challengeId: 1
   */
  const URL = `${urlPrefix}${challengeAction}?action=${action}&challengeId=${challengeId}`;
  return axios.put(
    URL, {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        timeStamp: "timestamp",
        accept: "*/*",
        "Access-Control-Allow-Origin": "*",
        withCredentials: true,
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
        "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
      },
    }
  );
};

export const viewScorecard = (challengeId, eventId) => {
  /**
   * action : 1
   * eventId: 1
   */
  const URL = `${urlPrefix}${viewChallengeScorecard}?eventId=${eventId}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const editUserProgramPaymentDetails = (userId, payload) => {
  const URL = `${urlPrefix}${insertOrEditPaymentOfUser}?userId=${userId}`;
  return axios.put(URL, payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getCountryListData = () => {
  const URL = `${urlPrefix}${getCountryList}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${secretToken}`,
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getProgramActivity = (subEventId , pageNo) => {
  const URL = `${urlPrefix}${getProgramWiseActivity}?subEventId=${subEventId}&page=${pageNo}&size=20`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const addProgramActivityData = (payload, formData, id) => {
  let URL = `${urlPrefix}${addProgramActivity}?activityEndDate=${payload.activityEndDate}&activityId=${payload.activityId}&activityStartDate=${payload.activityStartDate}&activityVisibility=${payload.activityVisibility}&actvityNote=${payload.actvityNote}&subEventId=${id}`;
  return axios.post(URL, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Content-type": "multipart/form-data; boundary=???",
    },
  });
};

export const getOldRecordingByProgram = (subEventId) => {
  const URL = `${urlPrefix}${viewOldRecording}?subEventId=${subEventId}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getClientTheme = (client = "") => {
  const URL = `${urlPrefix}clients/getCustomLoginScreen?client=${client}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${secretToken}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers": "accept, content-type, x-access-token, x-requested-with",
    },
  });
};


//siva
export const doUnRegisterUserFromEvent = (eventId, userId) => {
  const url = `${urlPrefix}${unRegisterUserFromEvent(eventId, userId)}`;
  return axios.post(url, {}, {
    headers: headers.getHeaders(),
  }).then(
    res => {
      return res.data;
    },
    err => {
    }
  );
};

export const doSubscribeUserFromEvent = (eventId, userId) => {
  const url = `${urlPrefix}v1.0/userSubscribeToEvent?eventId=${eventId}&userId=${userId}`;
  return axios.put(url,{}, {
    headers: headers.getHeaders(),
  }).then(
    res => {
      return res.data;
    },
    err => {
    }
  );
};

export const doUnSubscribeUserFromSubEvent= (eventId,subEventId, userId) => {
  const url = `${urlPrefix}v1.0/userUnsubscribeToSubEvent?eventId=${eventId}&subEventId=${subEventId}&userId=${userId}`;
  return axios.put(url,{}, {
    headers: headers.getHeaders(),
  }).then(
    res => {
      return res.data;
    },
    err => {
    }
  );
};
export const doSubscribeUserFromSubEvent=(eventId,subEventId,userId)=>{
  const url = `${urlPrefix}v1.0/userSubscribeToSubEvent?eventId=${eventId}&subEventId=${subEventId}&userId=${userId}`;
  return axios.put(url,{}, {
    headers: headers.getHeaders(),
  }).then(
    res => {
      return res.data;
    },
    err => {
    }
  );
}

export const getDashboardInfoData = (id) => {
  const URL = `${urlPrefix}${getDashboardInfo}?challengerZoneId=${id}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers":
        "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getDashboardPlotData = (id , dataSet) => {
  const URL = `${urlPrefix}${getPLotData}?challengerZoneId=${id}&dataSet=${dataSet}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers":
        "accept, content-type, x-access-token, x-requested-with",
    },
  });
};



export const gettransactionreport  = (fromDate , toDate, page , size) => {
  const URL = `${urlPrefix}${getTransactionReport}?fromDate=${fromDate}&toDate=${toDate}&page=${page}&size=${size}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers":
        "accept, content-type, x-access-token, x-requested-with",
    },
  });
};

export const getAvailablecoach  = (fromDate , page  , size) => {
  const URL = `${urlPrefix}${getAvailableCoach}?date=${fromDate}&page=${page}&size=${size}`;
  return axios.get(URL, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      timeStamp: "timestamp",
      accept: "*/*",
      "Access-Control-Allow-Origin": "*",
      withCredentials: true,
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,OPTIONS",
      "Access-Control-Allow-Headers":
        "accept, content-type, x-access-token, x-requested-with",
    },
  });
};