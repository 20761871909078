import React from "react";
import "../../../src/App.css";
import Logo from '../../assets/logo.png'
import { PrimaryButton } from "../Form";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return<div class="errorModule w-screen h-screen overflow-hidden" style={{display:'flex' , flexDirection:'column' , justifyContent:'center', alignItems:'center' , overflow:'hidden'}}>
      <div class="errorIcon">
        <img src={Logo} className='lg:h-24 lg:w-24 sm:h-12 sm:w-12'/>
      </div>
      <div class="errorMsg">Oops! Something went Wrong. Try Again</div>
     
      <PrimaryButton className="w-20 h-6 mt-5" onClick={() => {
        location.reload()
      }}> Refresh </PrimaryButton>
      
    </div>
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
